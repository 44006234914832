// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"}.dUaCAewWeJuTScQ7UMZf{padding:16px 16px 13px 16px;border-radius:8px;background-color:#083b94;font-family:var(--font-stack);font-size:18px;font-weight:600;text-align:center;color:#fff}.dUaCAewWeJuTScQ7UMZf:hover{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/styles/button.module.scss"],"names":[],"mappings":"AAAA,MACE,2JAAA,CAIF,sBACE,2BAAA,CACA,iBAAA,CACA,wBAAA,CACA,6BAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CACA,4BACE,cAAA","sourcesContent":[":root {\n  --font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,\n    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';\n}\n\n.fusionAuthButton {\n  padding: 16px 16px 13px 16px;\n  border-radius: 8px;\n  background-color: #083b94;\n  font-family: var(--font-stack);\n  font-size: 18px;\n  font-weight: 600;\n  text-align: center;\n  color: #fff;\n  &:hover {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fusionAuthButton": `dUaCAewWeJuTScQ7UMZf`
};
export default ___CSS_LOADER_EXPORT___;
